//.grid-container {
//    .paging.paging-fixed {
//        position: initial !important;
//    }
//}

.text-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.text-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.button-hover:hover {
    display: block;
    background-color: red;
    color: red;
}
.pdt20 {
    padding-top: 20px;
}
.pdb20 {
    padding-bottom: 20px;
}
.user-select {
    user-select: none;
}
// .dx-datagrid-nowrap.dx-datagrid-headers .dx-header-row > td {
//    padding: 5px !important;
// }
// .dx-command-edit{
//     display: none !important;
// }
@media (max-width: 580px) {
    .W05F0007-ActionToolbar {
        min-height: 101px !important;
    }
}

.w05f0007-grid {
    .dx-datagrid {
        .dx-row {
            & > td {
                border-right: none !important;
                border-left: none !important;
            }
            &.dx-state-hover:not(.dx-header-row) {
                .grid-action-w05f0007-hover {
                    display: table-cell !important;
                }
            }
            .grid-action-w05f0007-hover,
            .grid-action-w05f0007-hover.hidden {
                display: none;
            }
            .grid-action-opacity {
                opacity: 0;
            }
        }
    }
}
